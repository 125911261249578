import { Global, css } from '@emotion/react';
import { normalize } from 'polished';
import imageGalleryStyles from '../components/ImageGallery/imageGallery.globalStyles';

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');

  ${normalize()}
  html {
    box-sizing: border-box;
    font-family: 'Lato';
    scroll-behavior: smooth;
    scroll-padding-top: 90px;
  }

  @-moz-document url-prefix() {
    html {
      scroll-behavior: auto;
    }
  }

  html,
  body {
    height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    border-width: 0;
    border-style: solid;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  p {
    font-family: 'Lato';
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  ol {
    margin: 0;
    padding: 0;
    padding-left: 2.5rem;

    li {
      padding-left: 0.75rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-position: outside;
    padding-left: 2.5rem;

    li {
      padding-left: 0.75rem;
    }
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
  }

  .img-align-center {
    display: block;
    margin: auto;
  }

  .img-align-left {
    display: block;
    margin-right: auto;
  }

  .img-align-right {
    display: block;
    margin-left: auto;
  }

  img.img-full-width {
    width: 100%;
  }

  .text-center,
  .text-align-center,
  .center_aligned_text_block {
    text-align: center;
  }

  .text-left,
  .text-align-left,
  .left_aligned_text_block {
    text-align: left;
  }

  .text-right,
  .text-align-right,
  .right_aligned_text_block {
    text-align: right;
  }

  .justify_aligned_text_block {
    text-align: justify;
  }

  .underline-text {
    text-decoration: underline;
  }

  .ReactModal__Overlay--after-open {
    z-index: 2;
  }

  ${imageGalleryStyles}
`;

const Reset = () => <Global styles={globalStyles} />;

export default Reset;
