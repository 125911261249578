export const BASIC_FONT_SIZE = 16;
export const MOBILE_BREAKPOINT_INDEX = 1;
export const TABLET_BREAKPOINT_INDEX = 2;

export const NOT_COVERED_TITLE_PERCENTS = 1;
export const COVERED_TITLE_PERCENTS = 0;

export const BASIC_CONTAINER_WIDTH = [
  '100%',
  '100%',
  'calc((100% - 100px) * 0.61)',
];
export const CONTENT_TYPES_LEFT_MARGIN = '232px';

export const PREVIEW_LENGTH = 260;
