export const IS_NEXT_EXPORT =
  process.env.IS_NEXT_EXPORT ||
  (typeof window !== 'undefined' &&
    window.location.href.endsWith('index.html'));

export const absoluteToRelativeInternalLink = (href) => {
  if (typeof window === 'undefined') {
    return href;
  }
  const currentPath = window.location.href.split('/out/');

  if (typeof currentPath[1] === 'undefined') {
    return href;
  }

  const replacement = currentPath[1]
    .split('/')
    .slice(1)
    .map(() => '../')
    .join('');

  const hrefFiltered = href
    .replace('https://www.militaryperiscope.com/', '')
    .replace(/file:\/\/\/([a-zA-Z]:\/)?/gm, '');

  const link = `${replacement + hrefFiltered}/index.html`;

  return link.replaceAll('//', '/');
};
