/**
 * Finds match between url and some string from array of matches.
 *
 * @param {string} url
 * @param {Array<string>} arrayOfMatches
 * @returns {boolean} is url matched
 */
export function matchUrl(url, arrayOfMatches) {
  return arrayOfMatches.some((el) => url.includes(el));
}

/**
 * Finds match between url and some string from array of matches.
 *
 * @param {string} url
 * @param {Array<string>} arrayOfMatches
 * @returns {boolean} is url matched
 */
export function strictMathUrl(url, arrayOfMatches) {
  return arrayOfMatches.some((el) => url === el);
}
