export default function removeDomainFromUrl(url) {
  const regex = /^(https?:\/\/[^/]+)/i;
  const match = url.match(regex);

  if (match && match[1]) {
    const domain = match[1];
    return url.replace(domain, '');
  }

  return url;
}
