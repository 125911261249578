import querystring from 'querystring';
import Cookies from 'js-cookie';
import PAGINATION_LIMIT from '../consts/pagination-limit';
import {
  keysToCamelFromSnake,
  keysToSnakeFromCamel,
} from '../utils/caseconverters';

export const API_URL = process.env.WAGTAIL_API_URL;
export const WEBSITE_URL = process.env.WAGTAIL_API_URL?.split('/')
  .splice(0, 3)
  .join('');
export const API_VERSION = 'v1';
export const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_WAGTAIL_API_URL;
export const NEXT_PUBLIC_WEBSITE_URL =
  process.env.NEXT_PUBLIC_WAGTAIL_API_URL?.split('/').splice(0, 3).join('');
export const CAPTCHA_SITEKEY = process.env.NEXT_PUBLIC_CAPTCHA_SITEKEY;
export const { HOST_ENV } = process.env;
export const { DOMAIN_URL } = process.env;

export class WagtailApiResponseError extends Error {
  constructor(res, url, params) {
    super(`${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`);
    this.name = 'WagtailApiResponseError';
  }
}
export async function getRequest(url, params, options) {
  params = params || {};
  params = keysToSnakeFromCamel(params);

  let headers = options?.headers || {};
  headers = {
    'Content-Type': 'application/json',
    Authorization: 'Basic UXVpbnRhZ3JvdXA6d20zNDkwcmRpbkpka2FsczN3',
    ...headers,
  };
  const queryString = querystring.stringify(params);

  try {
    const res = await fetch(queryString ? `${url}?${queryString}` : url, {
      headers,
    });

    if (res.status === 404) {
      return {
        headers: res.headers,
        json: { status: 404 },
      };
    }

    if (res.status === 304 || res.status === 500) {
      return {
        headers: res.headers,
        json: {},
      };
    }

    if (res.status < 200 || res.status >= 300) {
      const error = new WagtailApiResponseError(res, url, params);
      error.response = res;
      throw error;
    }

    const json = await res.json();
    return {
      headers: res.headers,
      json: keysToCamelFromSnake(json),
    };
  } catch (e) {
    console.error('e', e);
  }
}

export async function postRequest(url, params, options) {
  params = params || {};
  params = keysToSnakeFromCamel(params);

  const csrftoken = Cookies.get('csrftoken');

  let headers = options?.headers || {};
  headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
    ...headers,
  };
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers,
  });

  if (res.status < 200 || res.status >= 300) {
    const error = new WagtailApiResponseError(res, url, params);
    error.response = await res.json();
    throw error;
  }

  const json = await res.json();
  return {
    headers: res.headers,
    json: keysToCamelFromSnake(json),
  };
}

export async function getPageSearch(search, params, options) {
  params = params || {};
  params = {
    search,
    ...params,
  };

  return getRequest(
    `${NEXT_PUBLIC_API_URL}/${API_VERSION}/page_search/`,
    params,
    options,
  );
}

export async function getPage(path, params, options) {
  params = params || {};
  params = {
    htmlPath: path,
    ...params,
  };
  if (process.env.IS_NEXT_EXPORT === '1') {
    options = {
      headers: {
        'X-Export-Secret-Key': process.env.EXPORT_SECRET_KEY,
        'User-Agent': process.env.EXPORT_USER_AGENT,
      },
      ...options,
    };
  }

  return getRequest(
    `${API_URL || NEXT_PUBLIC_API_URL}/${API_VERSION}/page_by_path/`,
    params,
    options,
  );
}

export async function getPasswordProtectedPage(
  restrictionId,
  pageId,
  params,
  options,
) {
  params = params || {};
  return postRequest(
    `${NEXT_PUBLIC_API_URL}/${API_VERSION}/password_protected_page/${restrictionId}/${pageId}/`,
    params,
    options,
  );
}

export async function getAllPages(isExport) {
  let res = null;
  let offset = 0;
  let results = null;

  do {
    res = await getRequest(`${API_URL}/${API_VERSION}/page_relative_urls/`, {
      limit: PAGINATION_LIMIT,
      mode: isExport && 'export',
      offset,
    });
    offset += PAGINATION_LIMIT;

    if (!results) {
      results = res;
    }
    results.json.items = results.json.items.concat(res.json.items);
  } while (offset < res.json.meta.totalCount);

  return results;
}

export async function getMainPages() {
  return getRequest(`${API_URL}/${API_VERSION}/main_pages/`);
}

export async function getSecondaryPages() {
  return getRequest(`${API_URL}/${API_VERSION}/secondary_pages/`);
}

export async function getGeneralSitemapPages(offset) {
  const params = {
    offset: offset || 0,
    limit: 500,
  };

  return getRequest(`${API_URL}/${API_VERSION}/general_sitemap_pages/`, params);
}

export async function getPagePreview(contentType, token, params, options) {
  params = params || {};
  params = {
    contentType,
    token,
    ...params,
  };

  return getRequest(`${API_URL}/${API_VERSION}/page_preview/`, params, options);
}

export async function getPublicViewData(slug, params, options) {
  return getRequest(
    `${NEXT_PUBLIC_API_URL}/${API_VERSION}/external_view_data/${slug}/`,
    params,
    options,
  );
}

export async function getViewData(slug, params, options) {
  return getRequest(
    `${API_URL}/${API_VERSION}/external_view_data/${slug}/`,
    params,
    options,
  );
}

export async function getRedirect(path, params, options) {
  params = params || {};
  params = {
    htmlPath: path,
    ...params,
  };

  return getRequest(
    `${API_URL}/${API_VERSION}/redirect_by_path/`,
    params,
    options,
  );
}
